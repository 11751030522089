import React from "react";
import { Link } from "react-router-dom";

import arrowLeft from "../../assets/images/common/arrow-left.svg";

const fallbackCopyTextToClipboard = (text = '') => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Avoid scrolling to bottom
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

const copyTextToClipboard = (text = '') => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  navigator.clipboard.writeText(text).catch((err) => {
    console.warn('Copy to clipboard failed:', err);
  });
};

const PageHeader = props => (
  <div className="page-header">
    {props.breadcrumbs && (
      <Link to={props.breadcrumbsLink}>
        <img src={arrowLeft} alt="arrow" />
        {props.breadcrumbs}
      </Link>
    )}
    {props.subTitle && <h3>{props.subTitle}</h3>}
    <div className="page-header--dates">
      <h2>{props.title}</h2>
      { props?.url && (
      <>
        <a href={props.url} target='_blank'>{props.url}</a>
        <button
          onClick={() => copyTextToClipboard(props.url)}
        >
          Copy
        </button>
      </>
      ) }
    </div>
  </div>
);

export default PageHeader;
