import React from 'react';
import Button from '../../../../components/Button';
import pencil from '../../../../assets/images/common/pencil.svg';
import trash from '../../../../assets/images/common/trash.svg';
import Tooltip from '../../../../components/Tooltip';

const PreviewCard = (props) => {
  return (
  <div className={`plan-card ${props.className ?? ""}`}>
    <div
      className="plan-image"
      style={
        props?.images?.horizontal
          ? { backgroundImage: `-webkit-image-set(url("${props?.images?.horizontal}") 1x, url("${props?.images?.horizontalX2}") 2x), url("${props?.images?.horizontal}")` }
          : { backgroundColor: "#eeeeee" }
      }
      onClick={props.onClick}
    >
      <div className="plan-name">
        <div className="plan-tooltip-button">
          <Button className={`${!props.isPlanActive && 'disabled'}`}>{props.name ?? props.label ?? props.placeholder}</Button>
          <Tooltip
            buttons={[
              {
                icon: pencil,
                title: "Rename",
                onClick: (e) => props.openRenameModal(e, props.name, props.id),
              },
              {
                icon: trash,
                title: "Delete",
                onClick: (e) => props.openDeleteModal(e, props.id),
              },
            ]}
          />
        </div>
      </div>
    </div>
  </div>
)};

export default PreviewCard;