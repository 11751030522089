import React from "react";

import Input from "../../../Input";
import Button from "../../../../components/Button";
import AddInfo from "../../../AddInfo";

const Notes = props => (
  <React.Fragment>
    <h6 className="page-section">Notes</h6>
    <AddInfo value={props.notes} onChange={props.changeNote} hideLabel noMargin />
  </React.Fragment>
);

export default Notes;
