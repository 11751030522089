import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import RoundedInput from "../../forms/RoundedInput";
import Table from "../../components/Table";
import Tooltip from "../../components/Tooltip";
import withPermissions from "../../utils/PermissionManager";

import edit from "../../assets/images/common/edit.svg";
import trash from "../../assets/images/common/trash.svg";
import lock from "../../assets/images/common/lock.svg";
import Modal from "../../components/Modal";
import Input from "../../forms/Input";
import { callResetAdminPassword } from "../../api/admins";

class Admins extends Component {
  state = {
    search: "",
    resettingAdminId: "",
    newPassword: "",
    resettingError: "",
    sort: { field: "", orderBy: "" },
  };

  componentDidMount() {
    this.props.getAdmins();
  }

  editAdmin = (id) => {
    this.props.history.push(`/settings/admins/edit-admin/${id}`);
  };

  searchKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({ search: e.target.value });
    }
  };

  getFilteredData = (data) => {
    if (!this.state.search) {
      return data;
    }

    return data.filter(
      (a) =>
        a.name.includes(this.state.search) ||
        a.email.includes(this.state.search)
    );
  };

  resetAdminPass = (id) => {
    this.setState({
      resettingAdminId: id,
    });
  };

  closeResetModal = () => {
    this.setState({
      resettingAdminId: "",
      newPassword: "",
    });
  };

  onPasswordChange = (e) => {
    this.setState({
      newPassword: e.target.value,
    });
  };

  onSortChange = (field, orderBy) => {
    this.setState({ sort: { field, orderBy } });
  };

  resetPasswordCall = async (password) => {
    try {
      await callResetAdminPassword(this.state.resettingAdminId, password);
      this.closeResetModal();
    } catch (error) {
      this.setState({
        resettingError: error.message,
      });
    }
  };

  setPassword = async () => {
    this.setState({ savingPassword: true });
    await this.resetPasswordCall(this.state.newPassword);
    this.setState({ savingPassword: false });
  };

  setRandomPassword = () => {
    this.setState({ savingRandomPassword: true });
    this.resetPasswordCall();
    this.setState({ savingRandomPassword: false });
  };

  render() {
    return (
      <>
        <Row justify="between">
          <Col md={9}>
            <RoundedInput
              placeholder="Search"
              onKeyPress={this.searchKeyPress}
            />
          </Col>
          <Col md={2}>
            <Link
              to="/settings/admins/add-admin"
              className={`button ${
                !this.props.can("create", "admin") ? "button-disabled" : ""
              }`}
            >
              + Add admin
            </Link>
          </Col>
        </Row>
        <Table
          perPage={10}
          sort={this.state.sort}
          onSort={this.onSortChange}
          columns={[
            {
              title: "ADMIN",
              target: "name",
              sortable: true,
            },
            { title: "EMAIL", target: "email", sortable: true },
            {
              title: "",
              target: "_id",
              renderer: (id) => (
                <Tooltip
                  buttons={[
                    this.props.can("edit", "admin") && {
                      title: "Edit",
                      icon: edit,
                      onClick: () => this.editAdmin(id),
                    },
                    this.props.can("edit", "admin") && {
                      title: "Reset password",
                      icon: lock,
                      onClick: () => this.resetAdminPass(id),
                    },
                    this.props.can("delete", "admin") && {
                      title: "Delete",
                      icon: trash,
                      onClick: () => this.props.removeAdmin(id),
                    },
                  ]}
                />
              ),
            },
          ]}
          data={this.getFilteredData(this.props.admins)}
        />
        <Modal
          className="password-reset-modal"
          visible={!!this.state.resettingAdminId}
          close={this.closeResetModal}
          buttons={[
            {
              children: "Random one",
              disabled: this.state.savingRandomPassword,
              outlined: true,
              small: true,
              onClick: this.setRandomPassword,
            },
            {
              children: "Reset",
              small: true,
              disabled: this.state.savingPassword || !this.state.newPassword,
              onClick: this.setPassword,
            },
          ]}
        >
          <h3>Reset password</h3>
          <Input
            error={this.state.resettingError}
            placeholder="New password"
            type="password"
            value={this.state.newPassword}
            onChange={this.onPasswordChange}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  admins: state.admins.all,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: () => dispatch({ type: "GET_ADMINS" }),
  removeAdmin: (id) => dispatch({ type: "REMOVE_ADMIN", id }),
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(Admins)
);
