import React from "react";
import RCSlider from "rc-slider";
import Tooltip from "rc-tooltip";

const Handle = RCSlider.Handle;

class Slider extends React.Component {
  handle = props => (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${props.value} mins`}
      visible={true}
      placement="bottom"
      key={props.index}
      align={{
        offset: [0, 5]
      }}
    >
      <Handle {...props} />
    </Tooltip>
  );

  render() {
    return (
      <div className="slider">
        <p className="label">Cooking time</p>
        <RCSlider min={0} max={120} {...this.props} handle={this.handle} />
      </div>
    );
  }
}

export default Slider;
