import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Tabs from "../Wellness/components/Tabs";
import { BackgroundsTab } from "./components/BackgroundsTab";
import { GeneralTab } from "./components/GeneralTab";
import { QuotesTab } from "./components/QuotesTab";
import { useGetDefaultActiveTab } from "./helpers/useGetDefaultActiveTab";
import { AdminsTab } from "./components/Admins";

export const SETTINGS_TABS = [
  { name: "General", component: <GeneralTab />, path: "" },
  { name: "Admins", component: <AdminsTab />, path: "admins" },
  { name: "Quotes", component: <QuotesTab />, path: "quotes" },
  { name: "Backgrounds", component: <BackgroundsTab />, path: "backgrounds" },
];

export const Settings = withRouter((navigation) => {
  const { activeTab } = useGetDefaultActiveTab(
    navigation?.match?.params?.slug,
    SETTINGS_TABS
  );

  const [currentTab, setCurrentTab] = useState(activeTab);

  useEffect(() => {
    navigation.history.push(`/settings/${SETTINGS_TABS[currentTab].path}`);
  }, [currentTab, navigation.history]);

  return (
    <div className="">
      <Layout>
        <PageHeader title="Settings" />
        <Tabs
          tabs={SETTINGS_TABS.map(({ name }) => name)}
          children={SETTINGS_TABS.map(({ component }) => component)}
          defaultActive={activeTab}
          onChange={(tab) => setCurrentTab(tab)}
        />
      </Layout>
    </div>
  );
});
