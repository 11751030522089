import React from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router-dom";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import RoundedInput from "../../forms/RoundedInput";
import Button from "../../components/Button";
import Card from "./components/Card";
import FilterModal from "./components/FilterModal";

import filter from "../../assets/images/common/filter.svg";
import arrowBack from "../../assets/images/common/arrow-back.svg";
import arrowNext from "../../assets/images/common/arrow-next.svg";
import withPermissions from "../../utils/PermissionManager";
import Select from "../../forms/Select";
import ConfirmModal from "../Rawkstars/components/ConfirmModal";
import { DownloadButton } from "../Rawkstars/components/DownloadButton/DownloadButton";
import { callGetRecipesCSV } from "../../api/recipe";
import { PageLimit } from "../../components/PageLimits/PageLimit";

export const LIMIT_RECIPES_OPTIONS = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

class Recipes extends React.Component {
  state = {
    searchInputValue: "",
    searchParams: {
      status: ["draft", "published"],
    },
    isFilterModalVisible: false,
    pageCount: 0,
    savedPage: 1,
    limit: 100,
    isConfirmDeleteRecipesVisible: false,
    deleteRecipeId: null,
  };

  componentDidMount() {
    this.getRecipes(1, true, this.state.limit);
  }

  getRecipes = (page, updatePagination, limit) => {
    const params = this.state.searchParams;
    params.perPage = limit;
    params.title = this.state.searchInputValue;

    this.props.getRecipes(page, updatePagination && this.setPageCount, params);
  };

  updateSearchValue = (e) => {
    this.setState({ searchInputValue: e.target.value });
  };

  onInputKeyPress = (e) => {
    if (e.key === "Enter") {
      this.getRecipes(1, true, this.state.limit);
    }
  };

  onFilterModalSubmit = (params) => {
    this.setState({
      searchParams: params,
    });

    setTimeout(() => {
      this.getRecipes(1, true, this.state.limit);
    });
  };

  onPageChange = (page) => {
    this.getRecipes(page.selected + 1, true, this.state.limit);
    this.setState({ savedPage: page.selected + 1 });
  };

  setPageCount = (pageCount) => {
    this.setState({ pageCount });
  };

  setLimit = (limit) => {
    this.setState({ limit });
  };

  openModal = () => {
    this.setState({
      isFilterModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      isFilterModalVisible: false,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      isConfirmDeleteRecipesVisible: false,
      deleteRecipeId: null,
    });
  };

  resetPagination = () => {
    this.setState({ savedPage: 1 });
  };

  navigateEditPage = (id) => {
    window.open(`/recipes/edit-recipe/${id}`);
  };

  handleRemove = (deleteRecipeId) => {
    this.setState({ isConfirmDeleteRecipesVisible: true, deleteRecipeId });
  };

  removeRecipe = (id) => {
    this.props.removeRecipe(id, () =>
      this.getRecipes(this.state.savedPage, true, this.state.limit)
    );
  };

  goReviewsPage = (id) => {
    window.open(`/reviews/recipe/${id}`);
  };

  changeLimitRecipes = (e) => {
    this.setLimit(e.target.value);
    this.resetPagination();
    this.getRecipes(1, true, e.target.value);
  };

  downloadAllRecipes = () => {
    const params = this.state.searchParams;
    params.search = this.state.searchInputValue;
    return callGetRecipesCSV(params);
  };

  render() {
    const filters =
      Object.keys(this.state.searchParams).length > 1 ||
      !!this.state.searchInputValue;
    return (
      <Layout>
        <PageHeader title="Recipes" />

        <Row>
          <Col md={6}>
            <RoundedInput
              placeholder="Search"
              value={this.state.searchInputValue}
              onChange={this.updateSearchValue}
              onKeyDown={this.onInputKeyPress}
            />
          </Col>
          <Col md={3}>
            <Button outlined onClick={this.openModal}>
              <img src={filter} alt="filter" />
              Filter
            </Button>
          </Col>
          <Col md={3}>
            <Link
              to="/recipes/add-recipe"
              // className={`button ${
              //   !this.props.can("create", "recipe") ? "button-disabled" : ""
              // }`}
              // todo
              className="button"
            >
              + Add recipe
            </Link>
          </Col>
        </Row>
        <Row className={this.props.loading ? "recipes-loading" : ""}>
          {this.props.recipes.map((recipe) => (
            <Col md={3} key={recipe._id}>
              <Card
                {...recipe}
                onEditClick={() => this.navigateEditPage(recipe._id)}
                onDeleteClick={() => this.handleRemove(recipe._id)}
                // canEdit={this.props.can("edit", "recipe")}
                // canDelete={this.props.can("delete", "recipe")}
                canEdit={true}
                canDelete={true}
                goReviewsPage={() => this.goReviewsPage(recipe._id)}
              />
            </Col>
          ))}
          <div className="pagination">
            {!this.props.recipes.length && filters && (
              <h6 className="page-section">
                No recipes found. Try modifying the search query or the Filters.
              </h6>
            )}
          </div>
        </Row>
        <div className="page-controller">
          <DownloadButton cb={this.downloadAllRecipes} fileName="Recipes" />
          <div className="pagination">
            {!!this.props.recipes.length && this.state.pageCount > 0 && (
              <ReactPaginate
                onPageChange={this.onPageChange}
                pageCount={this.state.pageCount}
                activeClassName="active"
                previousLabel={<img src={arrowBack} alt="back" />}
                nextLabel={<img src={arrowNext} alt="next" />}
              />
            )}
          </div>
          <PageLimit
            value={this.state.limit}
            options={LIMIT_RECIPES_OPTIONS}
            placeholder="Select recipe limit"
            onChange={this.changeLimitRecipes}
          />
        </div>
        <ConfirmModal
          visible={this.state.isConfirmDeleteRecipesVisible}
          close={this.closeConfirmModal}
          delete={(ids) => {
            this.removeRecipe(ids[0]);

            this.closeConfirmModal();
          }}
          content={"Are you sure you want to delete this recipe?"}
          ids={[this.state.deleteRecipeId]}
        />
        <FilterModal
          visible={this.state.isFilterModalVisible}
          close={this.closeModal}
          onSubmit={this.onFilterModalSubmit}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading["GET_RECIPES"],
  recipes: state.recipe.all,
});

const mapDispatchToProps = (dispatch) => ({
  getRecipes: (page, setPageCount, params) =>
    dispatch({ type: "GET_RECIPES", page, setPageCount, params }),
  removeRecipe: (id, onSuccess) =>
    dispatch({ type: "REMOVE_RECIPE", id, onSuccess }),
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Recipes))
);
