import React from "react";
import sortObjectList from "sort-object-list";
import ReactPaginate from "react-paginate";

import arrowUp from "../../assets/images/common/arrow-up.svg";
import arrowDown from "../../assets/images/common/arrow-down.svg";
import arrowBack from "../../assets/images/common/arrow-back.svg";
import arrowNext from "../../assets/images/common/arrow-next.svg";

class Table extends React.Component {
  state = { currentPage: 0, filter: null, desc: false };

  filter = (target, sortable) => {
    if (!sortable || !this?.props?.sort) return;
    const newOrderBy =
      target === this.props.sort.field ? this.props.sort.orderBy !== -1 : false;
    this.setState({
      filter: target,
      desc: newOrderBy,
    });

    let orderBy =
      target === this.props.sort.field
        ? this.props.sort.orderBy === -1
          ? 1
          : -1
        : -1;
    //

    this.props?.onSort && this.props.onSort(target, orderBy);
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page.selected,
    });
  };

  render() {
    let data = this.props.data;
    if (this.state.filter) {
      data = sortObjectList(data, {
        sortBy: this.state.filter,
        isDecreasing: this.state.desc,
      });
    }
    if (this.state.filter === "user") {
      data = data.sort(({ user }, { user: nextUser }) => {
        if (user.name > nextUser.name) return this.state.desc ? 1 : -1;
        if (user.name < nextUser.name) return this.state.desc ? -1 : 1;
        return 0;
      });
    }
    const pageCount = this.props.data.length / this.props.perPage;
    const startIndex = this.state.currentPage * this.props.perPage;
    const currentPageData = data.slice(
      startIndex,
      startIndex + this.props.perPage
    );

    return (
      <div className="table-container" ref={this?.props?.tableRef || null}>
        <table className="table">
          <thead>
            <tr>
              {this.props.columns
                .filter((item) => !item?.isHidden)
                .map((col, index) => {
                  const sortCondition = col.sort || col.target;
                  let title = col.title;
                  if (typeof col.title === "function") {
                    title = col.title();
                  }

                  return (
                    <th
                      key={index}
                      onClick={() => this.filter(sortCondition, col.sortable)}
                    >
                      <div>
                        {title}
                        {col.sortable && (
                          <div className="sorting">
                            {!(
                              sortCondition === this.props.sort.field &&
                              this.props.sort.orderBy !== 1
                            ) && <img src={arrowUp} alt="up" />}
                            {!(
                              sortCondition === this.props.sort.field &&
                              this.props.sort.orderBy !== -1
                            ) && <img src={arrowDown} alt="down" />}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((data, index) => (
              <tr key={index}>
                {this.props.columns
                  .filter((item) => !item?.isHidden)
                  .map((col, index) => (
                    <td key={index}>
                      {col.renderer
                        ? col.renderer(data[col.target])
                        : data[col.target]}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
        {this.props.perPage < data.length && (
          <div className="pagination">
            <ReactPaginate
              onPageChange={this.onPageChange}
              pageCount={pageCount}
              activeClassName="active"
              previousLabel={<img src={arrowBack} alt="back" />}
              nextLabel={<img src={arrowNext} alt="next" />}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Table;
