import React from "react";
import ClickOutside from "react-click-outside";

class Tooltip extends React.Component {
  state = { isOpen: false };

  toggle = (e) => {
    e.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen
    });
    console.log(this.state.isOpen);
    if (!this.state.isOpen) {
      const [table] = document.getElementsByClassName('table-container');
      if (table) {
        table.classList.add('with-scroll');
        table.scrollTo({ top: 0 });
      }
    }
  };

  close = () => {
    this.setState({
      isOpen: false
    });
    const [table] = document.getElementsByClassName('table-container');
        if (table) {
          table.classList.remove('with-scroll');
          table.scrollTo({ top: 0 });
        }
  };

  handleClick = (callback, e) => {
    this.close();
    callback(e);
  };

  render() {
    if (this.props.buttons.filter(Boolean).length === 0) {
      return null;
    }

    return (
      <ClickOutside onClickOutside={this.close}>
        <div className="tooltip">
          {this.props.isLoading ? (
            <div className="lds-dual-ring tooltip__loader"></div>
          ) : (
            <>
              <div className="tooltip-dots" onClick={this.toggle}>
                <div />
                <div />
                <div />
              </div>
              <div
                className={`tooltip-body ${this.state.isOpen ? "open" : ""} ${this.props.isLast ? 'last' : ''}`}
              >
                {this.props.buttons.filter(Boolean).map(button => (
                  <button
                    key={button.title}
                    className="tooltip-button"
                    onClick={(e) => this.handleClick(button.onClick, e)}
                  >
                    <img src={button.icon} alt={button.title} />
                    {button.title}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      </ClickOutside>
    );
  }
}
export default Tooltip;
