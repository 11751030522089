import React from 'react';

import edit from "../../../../assets/images/common/edit.svg";
import trash from "../../../../assets/images/common/trash.svg";
import close from "../../../../assets/images/common/close-b.svg";
import {callCreateIngredientForm, callDeleteIngredientForm, callEditIngredientForm} from "../../../../api/enum";
import { toast } from 'react-toastify';

class FormCard extends React.Component {
  state = {
    editing: false,
    formName: '',
  }

  componentDidMount() {
    this.setState({ formName: this.props.form.name });

    if (this.isNewForm()) {
      this.edit();
    }
  }

  isNewForm = () => this.props.form.name === '' && this.props.form._id === '';

  edit = () => {
    this.setState({ editing: true }, () => this.nameRef.focus());
  }

  handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      this.setState({
        editing: false,
        formName: e.target.innerText,
      });

      if (this.isNewForm() && !this.state.createdId) {
        try {
          const response = await callCreateIngredientForm(e.currentTarget.innerText);
          this.props.onIngredientFormCreate(response.data);
          this.setState({
            createdId: response.data._id,
          });
        } catch (error) {
          if (error.code === 409) {
            toast.error('Form with provided name already exists');
            this.props.onDeleteForm(this.props.form._id);
          }
        }
      } else {
        const name = e.currentTarget.innerText;
        const id = this.state.createdId || this.props.form._id;
        await callEditIngredientForm(id, name);
        this.props.onEditForm(id, name);
      }
    }
  };

  cancelEditing = () => {
    this.setState({
      editing: false,
    });

    this.nameRef.innerText = this.state.formName;
  };

  delete = async () => {
    // if (!this.props.form.safeToDelete) {
    //   this.showDeleteError()
    //   return;
    // }

    const id = this.state.createdId || this.props.form._id;
    await callDeleteIngredientForm(id);
    this.props.onDeleteForm(this.props.form._id);
  };

  showDeleteError = () => {
    this.setState({ error: 'Can\'t delete this form'});

    setTimeout(() => {
      this.setState({ error: '' })
    }, 2500);
  };

  render () {
    return (
      <div className="form">
        <p
          ref={ref => this.nameRef = ref}
          contentEditable={this.state.editing}
          className={`
            ${this.state.editing ? 'editing' : ''}
            ${this.state.error ? 'withError' : ''}
          `}
          onKeyPress={this.handleKeyPress}
        >
          {this.state.error || this.state.formName}
        </p>
        <div className="form-buttons">
          {this.state.editing
            ? <button onClick={this.cancelEditing}><img src={close} alt="Cancel" /></button>
            : (<>
              <button onClick={this.edit}><img src={edit} alt="Edit" /></button>
              <button onClick={this.delete}><img src={trash} alt="Delete" /></button>
            </>)
          }
        </div>
      </div>
    );
  }
}

export default FormCard;
