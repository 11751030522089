import React from "react";
import { call } from "../../api/core";

import Button from "../Button";
import loader from "../../assets/images/common/loader-web.svg";
import { useOptimizerImage } from "../../utils/useOptimizerImage";

class ImageUpload extends React.Component {
  state = {
    imageUrl: null,
    loading: false,
  };

  handleUpload = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (this.props.isPureFile) {
      this.props.onChange &&
        this.props.onChange({
          src: URL.createObjectURL(e.target.files[0]),
          file: e.target.files[0],
        });
      return;
    }

    this.setState({ loading: true }, () => {
      if (this.props.onUploadStart) {
        this.props.onUploadStart();
      }
    });

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    this.setState({
      imageUrl: URL.createObjectURL(e.target.files[0]),
    });

    const response = await call("/files/upload", "POST", {
      headers: { "Content-Type": "multipart/form-data" },
      body: formData,
    });

    this.setState(
      {
        loading: false,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(response.data.tmpFile);
        }
        if (this.props.onUploadEnd) {
          this.props.onUploadEnd();
        }
      }
    );
  };

  handleDelete = () => {
    if (this.props.isPureFile) {
      this.props.onChange && this.props.onChange({ src: null, file: null });
      return;
    }
    this.setState({
      imageUrl: null,
    });

    this.input.value = null;
    if (this.props.onChange) {
      this.props.onChange({ src: null, file: null });
    }
  };

  render() {
    const optimizedImage = useOptimizerImage({
      common: this.props.value.adminPreview,
      retina: this.props.value.adminPreviewX2,
    });

    return (
      <div className="image-upload">
        <div
          className={`
            image-preview
            ${!this.props.value.adminPreview ? "empty" : ""}
            ${this.state.loading ? "loading" : ""}
          `}
          style={optimizedImage}
        >
          {this.state.loading && (
            <img className="loader" src={loader} alt="loader" />
          )}
        </div>
        <input
          ref={(ref) => (this.input = ref)}
          id="upload-file"
          type="file"
          accept="image/*"
          multiple={false}
          style={{ display: "none" }}
          onChange={this.handleUpload}
          disabled={this.props.disableUpload}
        />
        <div className="buttons">
          {this.props.disableUpload ? (
            "You cannot upload files"
          ) : (
            <>
              <label htmlFor="upload-file" className="button button-small">
                Upload Photo
              </label>
              <Button small outlined onClick={this.handleDelete}>
                Delete
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ImageUpload;
