import { call, callBlob } from "../core";

export const callAddRecipe = (recipe) => {
  return call("/recipe", "POST", { body: recipe });
};

export const callGetUploadingLink = async (type) => {
  return call("/files/s3-link", "GET", { params: { type } }).then(
    ({ data }) => data.link
  );
};

export const callGetRecipes = (page, params) => {
  return call(`/recipe/list/${page}`, "GET", { params });
};

export const callRemoveRecipe = (id) => {
  return call(`/recipe/${id}`, "DELETE", {});
};

export const callUpdateRecipes = (id, body) => {
  return call(`/recipe/${id}`, "PUT", { body });
};

export const callGetIngredients = (search, page, dataType, signal) => {
  return call("/fdc/ingredients", "GET", {
    params: { search, page, dataType },
  }, signal);
};

export const callGetIngredientsList = () => {
  return call("/ingredients/list", "GET", {});
};

export const callGetIngredientData = (id, search) => {
  return call(`/fdc/ingredient/${id}`, "GET", { params: { search } });
};

export const callSaveFDCIngredient = (ingredient) => {
  return call("/fdc/ingredients", "POST", { ingredient });
};
export const callDeleteFDCIngredient = (fdcId) => {
  return call(`/fdc/ingredient/${fdcId}`, "DELETE", { params: {} });
};

export const callGetRecipesCSV = (params) => {
  return callBlob("/recipes/export", "GET", { params }).then((resp) => {
    return resp.data;
  });
};
