import React, { useEffect, useRef, useState } from "react";
import ClickOutside from "react-click-outside";
import cx from "classnames";
import close from "../../assets/images/common/close-b.svg";

const addOrDelete = (options, option) => {
  const isSelected = !!options?.find((item) => item.value === option.value);

  if (isSelected) {
    return options.filter((item) => item.value !== option.value);
  } else {
    return [...options, option];
  }
};

export const MultySelect = (props) => {
  const { label, placeholder, options, value, onChange } = props ?? {};
  const [isOpen, setIsOpen] = useState(false);

  const [innerValue, setInnerValue] = useState(value);
  const containerRef = useRef(null);
  const itemsRef = useRef([]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const [maxWidth, setMaxWidth] = useState(containerRef?.current?.clientWidth);

  const handleClickOutside = () => {
    handleChange();
    setIsOpen(false);
    updateWidth();
  };

  const handleChange = () => {
    if (isOpen && JSON.stringify(value) !== JSON.stringify(innerValue)) {
      onChange(innerValue?.map((item) => item.value));
    }
  };

  const handleClickItem = (clickedItem) => {
    setInnerValue((prev) => {
      return addOrDelete(prev, clickedItem) || [];
    });
    updateWidth();
  };

  useEffect(() => {
    updateWidth();
  }, [innerValue, value]);

  const updateWidth = () => {
    setMaxWidth(containerRef?.current?.clientWidth);
  };

  const visibleCount = !!itemsRef?.current?.length
    ? itemsRef?.current?.reduce(
        (acc, item) => {
          const { clientWidth = 0 } = item ?? {};
          const newWidth = acc.width + clientWidth + 8;
          if (maxWidth > newWidth && !acc.isFull) {
            return { count: acc.count + 1, width: newWidth };
          } else return { ...acc, isFull: true };
        },
        {
          count: 0,
          width: 0,
          isFull: false,
        }
      )
    : { count: 0, width: 0 };

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <div className="multy-select">
        <div className="multy-select__label">{label}</div>
        <div
          placeholder={placeholder}
          className={cx(
            "multy-select__input",
            isOpen && "multy-select__input--open"
          )}
          onClick={() => {
            handleChange();
            setIsOpen((prev) => !prev);
          }}
        >
          {innerValue.length ? (
            <div className="multy-select__selectedValue">
              <div className="multy-select__selectedItems" ref={containerRef}>
                {innerValue.map((item, index) => (
                  <div
                    className="multy-select__selectedItem"
                    key={index}
                    ref={(el) => (itemsRef.current[index] = el)}
                  >
                    <p>{item.label}</p>
                    <button
                      className="multy-select__selectedItemDelete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickItem(item);
                      }}
                    >
                      <img src={close} />
                    </button>
                  </div>
                ))}
              </div>
              {innerValue?.length - visibleCount?.count > 0 ? (
                <div
                  className="multy-select__selectedCount"
                  key={`${maxWidth}-${innerValue.length}-${innerValue.length -
                    visibleCount.count}`}
                >
                  <p>+{innerValue.length - visibleCount.count}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <p className="multy-select__placeholder">{placeholder}</p>
          )}
        </div>
        {!!isOpen && (
          <div className="multy-select__values">
            {options.map((option, i) => {
              const isSelected = innerValue.find(
                (selectItem) => selectItem.value === option.value
              );
              return (
                <div
                  key={i}
                  className={cx(
                    "multy-select__valueItem",
                    isSelected && "multy-select__valueItem--selected"
                  )}
                  onClick={() => handleClickItem(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </ClickOutside>
  );
};
