export const ShoppingListUnitsEnum = {
  // whole:"whole",
  cup: 'cup',
  tbsp: 'tbsp',
  tsp: 'tsp',
  flOz: 'fl oz',
  can: 'can',
  oz: 'oz',
  lbs: 'lbs',
  inch: 'inch',
  drops: 'drops',
  ml: 'ml'
};
