import * as ActionTypes from "./actionTypes";
import { DEFAULT_SUBSCRIPTION_STATUSES } from "../../containers/Rawkstars";

export const EMPTY_FILTER = {
  subscriptionStatuses: [],
  subscriptionTypes: [],
  hasAllergy: [],
  isRecipeAuthor: [],
  registrationDateStart: "",
  registrationDateEnd: "",
  lastActivityStart: "",
  lastActivityEnd: "",
};

const initialState = {
  all: [],
  meta: {},
  search: "",
  page: 1,
  filters: {
    subscriptionStatuses: DEFAULT_SUBSCRIPTION_STATUSES,
    subscriptionTypes: [],
    hasAllergy: [],
    isRecipeAuthor: [],
    registrationDateStart: "",
    registrationDateEnd: "",
    lastActivityStart: "",
    lastActivityEnd: "",
    deletedOnly: false,
  },
  sort: { field: null, orderBy: -1 },
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_USERS:
      return {
        ...state,
        all: action.users.users,
        meta: action.users.meta,
      };
    case ActionTypes.REMOVE_USER:
      return {
        ...state,
        all: state.all.filter((a) => !action.id.includes(a._id)),
      };
    case ActionTypes.RESTORE_USER:
      return {
        ...state,
        all: state.all.filter((user) => !action.id.includes(user._id)),
      };
    case ActionTypes.SET_USERS_SEARCH:
      return {
        ...state,
        search: action.search,
      };
    case ActionTypes.SET_USERS_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case ActionTypes.SET_USERS_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case ActionTypes.SET_USERS_SORT:
      return {
        ...state,
        sort: action.sort,
      };
    default:
      return state;
  }
}
