import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";

const AddInfo = (props) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = editorRef.current.getEditor();
    const editorElement = editor.root;
    
    editorElement.style.height = 'auto';
    editorElement.style.height = editorElement.scrollHeight + 'px';
  }, [props.value]);

  return (
    <div className={`add-info ${props.noMargin ? "no-margin" : ""}`}>
      {/* {!props.hideLabel && <h6>Add Info</h6>} */}
      {props.label ? (
        <h6 className={`${props.classNames.label}`} htmlFor={props.label}>
          {props.label}
        </h6>
      ) : (
        <h6>Add Info</h6>
      )}
      <ReactQuill
        onChange={props.onChange}
        value={props.value}
        ref={editorRef}
        modules={{
          toolbar: [
            ["link"],
          ],
        }}
      />
      <p className="error">{props.error}</p>
    </div>
  )
};

export default AddInfo;
