import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Input from "../../../Input";
import Select from "../../../Select";
import Button from "../../../../components/Button";
import Counter from "../../../../components/Counter";
import Modal from "../../../../components/Modal";
import { callGetIngredients } from "../../../../api/recipe";
import RoundedInput from "../../../RoundedInput";
import arrowBack from "../../../../assets/images/common/arrow-back.svg";
import arrowNext from "../../../../assets/images/common/arrow-next.svg";
import dragIcon from "../../../../assets/images/common/drag.svg";
import ReactPaginate from "react-paginate";
import { callGetIngredientData, callDeleteFDCIngredient } from "../../../../api/recipe";
import FormCard from './FormCard';
import { ShoppingListUnitsEnum } from '../../../../utils/shoppingList';
import { toast } from "react-toastify";
import { ingredientUnits } from "../../../../utils/helpers";
import SearchSelect from "../../../../components/SearchSelect";
import { createEmptyIngredient } from "../..";
import Axios from "axios";

class Ingredients extends Component {
  state = {
    isModalVisible: false,
    searchValue: '',
    fetchedIngredients: [],
    pageCount: 0,
    currentPage: 0,
    dataType: "",
    manageModalVisible: false,
    isAddIngredientModalVisible: false,
    isHeading: false
  };

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.debounceTimeout = null;
    this.cancelTokenSource = null;
  } 

  openManageModal = () => {
    this.setState({
      manageModalVisible: true,
    });
  };

  closeManageModal = () => {
    this.setState({
      manageModalVisible: false,
    });
  };

  openModal = (heading) => {
    this.setState({
      isModalVisible: true,
      isHeading: heading
    })
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
      fetchedIngredients: [],
      pageCount: 0,
      currentPage: 0,
      isHeading: false,
    })
  };

  getIngredients = async (search, page, signal) => {
    try {
      const response = await callGetIngredients(search || this.state.searchValue, page, this.state.dataType, signal);

      this.setState({
        fetchedIngredients: response.data.ingredients,
        pageCount: response.data.pages,
      });
    } catch (err) {
      if (err.code === undefined) return;
      toast.error('Get ingredients server error');
    }
  };

  handleIngredientChoose = async (ingredient) => {
    this.setState({
      openedIndex: null,
    });
    const isHeading = this.state.isHeading;
    this.closeModal();
    try {
      const nutrition = await callGetIngredientData(ingredient.fdcId, this.state.searchValue);
      const emptyIngredient = createEmptyIngredient(false);
      emptyIngredient.name.nutrition = nutrition.data;

      emptyIngredient.name.name = this.state.searchValue;
      emptyIngredient.name.description = ingredient.description;
      emptyIngredient.fdcId = ingredient.fdcId;
      emptyIngredient.isNew = true;
      emptyIngredient.heading = isHeading;

      this.props.addIngredient(false, emptyIngredient);
      // this.props.addIngredient(false)

      // this.props.changeIngredient(
      //   {
      //     name: ingredient.name.name || ingredient.description,
      //     fdcId: ingredient.fdcId,
      //     description: ingredient.description,
      //     nutrition: nutrition.data
      //   },
      //   ingredientIndex,
      //   'name'
      // );
      // this.props.changeIngredient({ target: { value: nutrition.data.measurementUnits.length > 1 ? nutrition.data.measurementUnits[1].unit : nutrition.data.servingUnit } }, ingredientIndex, "measure");
    } catch (e) {
      console.log('e: ', e);
      toast.error(e.code === 404 ? 'Not found' : 'Get ingredient server error. Please try again');
    }
  };

  changeSearchValue = e => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  handleInputChange(event) {
    const value = event.target.value;
    this.setState({ searchValue: value });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Operation canceled due to new request.');
    }

    this.debounceTimeout = setTimeout(() => {
      this.setState({ debouncedValue: value, currentPage: 0 }, () => {
        this.cancelTokenSource = Axios.CancelToken.source();

        this.getIngredients(value, 1, this.cancelTokenSource.token);
      });
    }, 700);
  }

  componentWillUnmount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Component unmounted.');
    }
  }

  onSearchKeyPress = e => {

    if (e.key === 'Enter') {
      this.setState({ currentPage: 0 });
      this.getIngredients(e.target.value, 1);
    }
  };

  onPageChange = ({ selected }) => {
    this.setState({
      currentPage: selected,
    });

    this.getIngredients(null, selected + 1);
  };

  setDataType = (e) => {

    this.setState({ dataType: e.target.value }, () => {
      if (this.state.searchValue) {
        this.setState({ currentPage: 0 });
        this.getIngredients(this.state.searchValue, 1);
      }
    })
  }
  deleteCustomIngredient = (fdcId) => {
    this.setState({ fetchedIngredients: this.state.fetchedIngredients.filter(i => i.fdcId !== fdcId) }, () => callDeleteFDCIngredient(fdcId).then(success => { }, error => { }).catch(error => { }))
  }
  getHeadingRow = (ingredient, index) => {
    return <Row style={{ marginBottom: "10px" }}  className="row">
      <Col md={12}>
        <div className="ingredient-name">
          <div className={`ingredient-options`}>
            <img src={dragIcon} />
            {index > 0 && <button onClick={() => this.props.onIngredientDeleted(index)} className="delete-button"></button>}
            {/* {index !== (this.props.ingredients.length - 1) && <button onClick={() => this.props.swapDown(index)} className={`swap down`}></button>}
            {index !== 0 && <button onClick={() => this.props.swapUp(index)} className="swap up"></button>} */}
          </div>
          <Input
            placeholder="Enter Heading Title here"
            zIndex={index}
            id={`ingredient-${index}-name`}
            // label={index === 0 && "Ingredient"}
            value={ingredient.name && ingredient.name.name}
            onChange={e => this.props.changeIngredient(
              { name: e.target.value, fdcId: ingredient.name && ingredient.name.fdcId },
              index,
              "name"
            )}
            error={
              this.props.errors && this.props.errors[index] && this.props.errors[index].name
            }

          />

        </div>
      </Col>
    </Row>
  }
  getIngredientRow = (ingredient, index) => {
    const extraOption = this.props.forms.find(el => el.name === ingredient.form) ? {
      value: '',
      label: '',
      disabled: true
    } : {
      value: ingredient.form,
      label: ingredient.form,
      disabled: true
    };

    return <Row style={{ marginBottom: "10px" }} className="row">
      <Col md={3}>
        <div className="ingredient-name">
          <div className={`ingredient-options`}>
            <img src={dragIcon} />
            {index > 0 && <button onClick={() => this.props.onIngredientDeleted(index)} className="delete-button"></button>}
            {/* {index !== (this.props.ingredients.length - 1) && <button onClick={() => this.props.swapDown(index)} className={`swap down`}></button>}
            {index !== 0 && <button onClick={() => this.props.swapUp(index)} className="swap up"></button>} */}
          </div>
          {/* <Input
            zIndex={index}
            id={`ingredient-${index}-name`}
            placeholder="Type here"
            label={index === 0 && "Ingredient"}
            value={ingredient.name && ingredient.name.name}
            onChange={e => this.props.changeIngredient(
              { name: e.target.value, fdcId: ingredient.name && ingredient.name.fdcId },
              index,
              "name"
            )}
            error={
              this.props.errors && this.props.errors[index] && this.props.errors[index].name
            }
          /> */}
          <SearchSelect
            options={this.props.list.map(el => ({ value: el.name.fdcId, label: el.name.name }))}
            onOptionSelect={option => {
              this.props.changeIngredient(
                { name: option.label, fdcId: option.value },
                index,
                "name"
              )
            }}
            defaultOption={{ value: ingredient.name.fdcId, label: ingredient.name.name }}
            // label={index === 0 && "Ingredient"}
          />
        </div>
      </Col>
      <Col md={3}>
        <Select
          class="form-select"
          zIndex={index + 1}
          options={[{
            value: "",
            label: "Select..."
          }, extraOption, ...this.props.forms.map(type => ({
            value: type.name,
            label: type.name
          }))].filter(el => el.label)}
          placeholder="Select"
          // label={index === 0 && "Form"}
          value={ingredient.form}
          onChange={e => this.props.changeIngredient(e, index, "form")}
          error={
            this.props.errors && this.props.errors[index] && this.props.errors[index].form
          }
        />
      </Col>
      <Col md={4}>
        <Row>
          <Col md={2}>
            <div>
              <div className="display-label" style={{height: "40px"}}>
                <label>Display:</label>
              </div>
              <div className="display-label" style={{height: "40px"}}>
                <label>Shopping:</label>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div>
              <div className="display-container">
                <Input
                  type="text" 
                  placeholder="Display amount"
                  value={ingredient.displayAmount} 
                  onChange={e => {
                    this.props.changeIngredient(e, index, "displayAmount")
                    this.props.changeIngredient(e, index, "quantity")
                  }}
                />
              </div>
              <div className="display-container">
                <Input
                  type="text" 
                  name="shoppingAmount"
                  placeholder="Shopping amount"
                  value={ingredient.shoppingList ? ingredient.shoppingList.amount : ""}
                  onChange={e => this.props.changeIngredient(e, index, "shoppingAmount")}
                />
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div style={{width: "100%"}}>
              <div className="display-container">
                <Select
                  class="form-select"
                  name="displayUnit"
                  zIndex={index + 4}
                  options={[
                    {label: "Select display unit", value: "" },
                    ...Object.entries(ShoppingListUnitsEnum).map(([key, value]) => ({
                    value: value,
                    label: value
                  })), extraOption].filter(el => el.label)}
                  placeholder="Display"
                  value={ingredient.displayUnit}
                  onChange={e => {
                    this.props.changeIngredient(e, index, "displayUnit")
                    this.props.changeIngredient(e, index, "measure")
                  }}
                />
                {/* <select name="displayUnit" 
                  onChange={e => {
                    this.props.changeIngredient(e, index, "displayUnit")
                    this.props.changeIngredient(e, index, "measure")
                  }}
                  value={ingredient.displayUnit}
                >
                  <option value="whole">...</option>
                  {Object.keys(ShoppingListUnitsEnum).map(key => <option value={ShoppingListUnitsEnum[key]} key={key}>{ShoppingListUnitsEnum[key]}</option>)}
                </select> */}
              </div>
              <div className="display-container">
                <Select
                  class="form-select"
                  name="shoppingUnit"
                  zIndex={index + 4}
                  options={[
                    {label: "Select shopping unit", value: "" },
                    ...Object.entries(ShoppingListUnitsEnum).map(([key, value]) => ({
                    value: value,
                    label: value
                  })), extraOption].filter(el => el.label)}
                  placeholder="Shopping"
                  value={ingredient.shoppingList ? ingredient.shoppingList.unit : ""}
                  onChange={e => this.props.changeIngredient(e, index, "shoppingUnit")}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <Select
          zIndex={index + 4}
          options={[{
            value: "",
            label: "Select..."
          }, ...this.props.categories.map(type => ({
            value: type._id,
            label: type.name
          }))]}
          placeholder="Select"
          value={ingredient.category}
          onChange={e => this.props.changeIngredient(e, index, "category")}
          error={
            this.props.errors &&
            this.props.errors[index] &&
            this.props.errors[index].category
          }
        />
      </Col>
    </Row>;
  }

  render() {

    return (

      <React.Fragment>
        <div className="title-container">
          <div className="title-wrapper">
            <h6 className="page-section">Ingredients</h6>
            <button className="manage-forms-button" onClick={this.openManageModal}>Manage forms</button>
          </div>
          <div className="title-counter">
            <p>Servings</p>
            <Counter onChange={this.props.onServingsChange} value={this.props.servings} />
          </div>
        </div>
        <DragDropContext onDragEnd={this.props.onDragEnd}>
          <Droppable droppableId="rows-container">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Row style={{ marginBottom: "10px" }}  className="row">
                  <Col md={3}>Ingredients</Col>
                  <Col md={3}>Form</Col>
                  <Col md={4}>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={5}>Quantity</Col>
                      <Col md={5}>Unit</Col>
                    </Row>
                  </Col>
                  <Col md={2}>Category</Col>
                </Row>
                {this.props.ingredients.map((ingredient, index) => ingredient.heading
                ? (
                  <Draggable key={`row-${index + 1}`} draggableId={`row-${index + 1}`} index={index}>
                    {provided => (
                      <div
                        className="row"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {this.getHeadingRow(ingredient, index)}
                      </div>
                    )}
                  </Draggable>
                ) : (
                  <Draggable key={`row-${index + 1}`} draggableId={`row-${index + 1}`} index={index}>
                    {provided => (
                      <div
                        className="row"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {this.getIngredientRow(ingredient, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button text onClick={() => this.props.addIngredient(false)}>
            + Add ingredient
          </Button>
          <Button style={{ marginLeft: "20px" }} text onClick={() => this.props.addIngredient(true)}>
            + Add heading
          </Button>
          <Button style={{ marginLeft: "20px" }} text onClick={() => this.openModal(false)}>
            + Add new ingredient
          </Button>
        </div>
        <Modal
          className="ingredients-modal"
          visible={this.state.isModalVisible}
          close={this.closeModal}
          buttons={[{
            onClick: this.closeModal,
            children: 'Close',
            outlined: true,
            small: true,
            limited: true,
          }]}
        >
          <div className="rounded-container with-select">
            <input
              onChange={this.handleInputChange}
              value={this.state.searchValue} type="text" placeholder="Ingredient Name" />
            {/* <select value={this.state.dataType} onChange={this.setDataType}>
              <option value="history">Search History</option>
              <option value="SR Legacy">Raw Ingredients</option>
              <option value="Branded">Branded Ingredients</option>
              <option value="">All Raw and Branded</option>
            </select> */}
          </div>

          {!!this.state.fetchedIngredients.length ? <div className="ingredient-buttons">
            {this.state.fetchedIngredients.map(ingredient =>
              <div className="ingredient-button">
                <button
                  onClick={() => this.handleIngredientChoose(ingredient)}

                >
                  {ingredient.description} <span className="ingredient-calories">{ingredient.calories}</span>
                  <div className="ingredient-brand">{ingredient.brandOwner}</div>
                </button>
                {this.state.dataType === "history" && <button className="delete" onClick={() => this.deleteCustomIngredient(ingredient.fdcId)}></button>}
              </div>
            )}
          </div> : <p className="empty-message">No ingredients found.</p>}
          {!!this.state.fetchedIngredients.length && this.state.pageCount > 1 && (
            <div className="pagination">
              <ReactPaginate
                forcePage={this.state.currentPage}
                onPageChange={this.onPageChange}
                pageCount={this.state.pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                activeClassName="active"
                previousLabel={<img src={arrowBack} alt="back" />}
                nextLabel={<img src={arrowNext} alt="next" />}
              />
            </div>
          )}
        </Modal>
        <Modal visible={this.state.manageModalVisible} buttons={[{
          children: 'Close',
          onClick: this.closeManageModal,
          limited: true,
          small: true,
          outlined: true
        }, {
          children: 'Add new',
          onClick: this.props.addEmptyIngredientForm,
          limited: true,
          small: true,
        }]}>
          <div className="manage-forms">
            {this.props.forms.map(form => <FormCard
              key={form._id}
              onEditForm={this.props.editIngredientForm}
              onDeleteForm={this.props.deleteIngredientForm}
              onIngredientFormCreate={this.props.onIngredientFormCreate}
              form={form}
            />)}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Ingredients;
