import React from "react";

import Checkbox from "../../../Checkbox";

const Allergens = props => (
  <React.Fragment>
    <h6 className="page-section">Allergies, intolerances or dislikes</h6>
    <div className="allergens-list">
      {props.data.sort((a,b)=>a.name>b.name?1:-1).map(allergen => (
        <Checkbox
          value={props.values.includes(allergen._id)}
          onChange={() => props.onAllergenChange(allergen._id)}
          name={allergen.name}
        />
      ))}
    </div>
  </React.Fragment>
);

export default Allergens;
