import React from "react";
import ClickOutside from "react-click-outside";

import Input from "../Input";

import loader from "../../assets/images/common/loader-web.svg";
import Checkbox from "../Checkbox";

class InputWithCheckboxes extends React.Component {
  state = {
    isMenuActive: false
  };

  onFocus = () => {
    this.setState({
      isMenuActive: true
    });
  };

  onClickOutside = () => {
    this.setState({
      isMenuActive: false
    });
  };

  selectedValue = (value) => {
    if (value > 0) {
      return value
    }
    else return 'None'
  }

  render() {
    return (
      <ClickOutside onClickOutside={this.onClickOutside}>
        <p className="label">{this.props.label}</p>
        <div className="suggest-input">
          <Input
            value={`Selected: ${this.selectedValue(this.props.values.length)}`}      
            onFocus={this.onFocus}
            placeholder={this.props.placeholder}
            error={this.props.error}
            postfix={this.props.postfix}
            isDropdownVisible={this.state.isMenuActive}
          />
          <div className={`options ${this.state.isMenuActive ? "active" : ""}`}>
            {this.props.options.map(option => (
              <div className="option">
                <Checkbox
                  name={option.label}
                  value={this.props.values.includes(option.value)}
                  onChange={() => this.props.onChange(option.value)}
                />
              </div>
            ))}
          </div>
        </div>
      </ClickOutside>
    );
  }
}

export default InputWithCheckboxes;
