import React from "react";

import edit from "../../../assets/images/common/edit.svg";
import trash from "../../../assets/images/common/trash.svg";

import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import { transformDateBackToFront } from "../../../utils/helpers";
import BackgroundPreview from "./BackgroundPreview";

export const TableBackgrounds = (props) => {
  const { editQuoteButtonHandler } = props;
  const backgrounds = useSelector((state) => state.backgrounds.backgrounds);
  const dispatch = useDispatch();

  return (
    <Table
      perPage={10}
      columns={[
        {
          title: "Backgrounds",
          target: "images",
          renderer: (images) => <BackgroundPreview images={images}/>,
        },
        {
          title: "Start date",
          target: "startDate",

          renderer: (startDate) => {
            
            return transformDateBackToFront(startDate)
          },
        },
        {
          title: "End date",
          target: "endDate",
          renderer: (endDate) => {
            return transformDateBackToFront(endDate)
          },
        },
        {
          title: "",
          target: "_id",
          renderer: (id) => (
            <Tooltip
              buttons={[
                {
                  title: "Edit",
                  icon: edit,
                  onClick: () => editQuoteButtonHandler(id),
                },
                {
                  title: "Delete",
                  icon: trash,
                  onClick: () => dispatch({ type: "REMOVE_BACKGROUND", id }),
                },
              ]}
            />
          ),
        },
      ]}
      data={backgrounds}
    />
  );
};
