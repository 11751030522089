import React from "react";

import Input from "../../../Input";
import Button from "../../../../components/Button";
import AddInfo from "../../../AddInfo";
import Textarea from "../../../Textarea";

const Instructions = props => (
  <React.Fragment>
    <h6 className="page-section">Instructions</h6>
    {props.instructions.map((instruction, index) => (
      <Textarea
        label={`Step ${index + 1}`}
        placeholder="Type here"
        value={instruction}
        onChange={e => props.changeInstruction(e, index)}
      />
    ))}
    <Button text onClick={props.addInstruction}>
      + Add
    </Button>
  </React.Fragment>
);

export default Instructions;
