import React from "react";

import Tooltip from "../../../../components/Tooltip";

import star from "../../../../assets/images/common/star.svg";
import cooking from "../../../../assets/images/common/cookings.svg";
import edit from "../../../../assets/images/common/edit.svg";
import trash from "../../../../assets/images/common/trash.svg";
import { useOptimizerImage } from "../../../../utils/useOptimizerImage";

const Card = (props) => {
  const optimizedImage = useOptimizerImage({
    common: props?.images?.big,
    retina: props?.images?.bigX2,
  });
  
  return (
    <div className="recipe-card">
      <div className="image" style={optimizedImage}></div>
      <div className="body">
        <p className="name">{props.title}</p>
        <div className="info">
          <div className="info-element">
            <img src={star} alt="star" />
            {Math.round(props.rating * 10) / 10}
          </div>
          <div className="info-element hide-1440">
            <img src={cooking} alt="cooking" />
            {props.users} cooking
          </div>
        </div>
        <div style={{ position: "absolute", bottom: "20px", right: "30px" }}>
          <Tooltip
            buttons={[
              {
                title: "Reviews",
                icon: star,
                onClick: props.goReviewsPage,
              },
              props.canEdit && {
                title: "Edit",
                icon: edit,
                onClick: props.onEditClick,
              },
              props.canDelete && {
                title: "Delete",
                icon: trash,
                onClick: props.onDeleteClick,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
