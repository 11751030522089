import React from "react";

import edit from "../../../assets/images/common/edit.svg";
import trash from "../../../assets/images/common/trash.svg";

import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import { transformNumberWeek } from "../../../utils/helpers";
import { useState } from "react";

export const TableQuotes = (props) => {
  const { editQuoteButtonHandler } = props;
  const [sort, setSort] = useState({ field: "", orderBy: "" });
  const quotes = useSelector((state) => state.quotes.quotes);
  const dispatch = useDispatch();

  return (
    <Table
      perPage={10}
      sort={sort}
      onSort={(field, orderBy) => {
        setSort({ field, orderBy });
      }}
      columns={[
        {
          title: "Content",
          target: "body",
          sortable: true,
          renderer: (content) =>
            `${content.slice(0, 20)}${content.length > 30 ? "..." : ""}`,
        },
        {
          title: "Author",
          target: "author",
          sortable: true,
        },

        {
          title: "Week",
          target: "number",
          sortable: true,
          renderer: (number) => {
            return `Week №${number}`;
          },
        },

        {
          title: "",
          target: "_id",
          renderer: (id) => (
            <Tooltip
              buttons={[
                {
                  title: "Edit",
                  icon: edit,
                  onClick: () => editQuoteButtonHandler(id),
                },
                {
                  title: "Delete",
                  icon: trash,
                  onClick: () => dispatch({ type: "REMOVE_QUOTE", id }),
                },
              ]}
            />
          ),
        },
      ]}
      data={quotes}
    />
  );
};
