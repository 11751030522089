import React, { useState, useMemo, useCallback, useEffect } from 'react';
import ClickOutside from "react-click-outside";
import arrowDown from '../../assets/images/common/arrow-down.svg'

const Option = React.memo(({ option, onSelect }) => (
  <div className="option" onClick={() => onSelect(option)}>
    {option.label}
  </div>
));

const SearchSelect = ({ label,options, onOptionSelect, defaultOption }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption || null);
  const [availableOptions, setAvailableOptions] = useState(options);

  useEffect(() => {
    if (selectedOption) {
      setSearchTerm(selectedOption.label);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (options.length) {
      setAvailableOptions(options);
    }
  }, [options])

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption)
    }
  }, [defaultOption])

  const filteredOptions = useMemo(() => {
    return availableOptions.filter(option =>
      option.label.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [searchTerm, availableOptions]);

  const toggleDropdown = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  }, []);

  const handleOptionSelect = useCallback((option) => {
    setSelectedOption(option);
    onOptionSelect(option);
    setIsOpen(false);
  }, [onOptionSelect]);

  return (
    <div className="select-component input-container wrapper">
      {label && <label htmlFor={label}>{label}</label>}
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        onClick={toggleDropdown}
        placeholder="Select an option..."
        aria-expanded={isOpen}
        className="select-input"
      />
      <div className={`global-postfix ${isOpen ? 'active' : ''}`}>
        <img src={arrowDown} className='select-arrow' />
      </div>
      {isOpen && (
        <ClickOutside
          onClickOutside={() => {
            setIsOpen(false);     
          }}
        >
        <div className="dropdown">
          {filteredOptions.map(option => (
            <Option
              key={option.label}
              option={option}
              onSelect={handleOptionSelect}
            />
          ))}
        </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default React.memo(SearchSelect);