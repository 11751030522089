import React from "react";

import { MaxNumberPlans } from "./MaxNumberPlans";

export const GeneralTab = () => {
  return (
    <div>
      <MaxNumberPlans />
    </div>
  );
};
