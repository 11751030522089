import React from "react";

import Card from "./Card";
import Day from "./Day";

const Column = props => {
  const data = {
    Breakfast: props.data.filter(m => m.category === "Breakfast"),
    Lunch: props.data.filter(m => m.category === "Lunch"),
    Dinner: props.data.filter(m => m.category === "Dinner"),
    Snack: props.data.filter(m => m.category === "Snack"),
    Dessert: props.data.filter(m => m.category === "Dessert")
  };
  return (
    <div className="plan-column">
      <Day date={props.date} withoutDate={props.withoutDate} />
      {Object.keys(data).map((key, i) => (
        <>
          <h4 className="row-title">{props.index === 0 && key}</h4>
          {data[key].length ? (
            data[key].map(meal => (
              <Card
                {...meal}
                onPortionUpdate={value =>
                  props.updateMeal(meal._id, { ...meal, portions: value }, true)
                }
                onDeteleClick={() => props.removeMeal(meal._id)}
                onSwapClick={() => props.swapMeal(meal._id)}
                canEdit={props.canEdit}
                canDelete={props.canDelete}
              />
            ))
          ) : (
            <Card
              empty
              onClick={() => props.addMeal(props.date, key, props.index)}
              canCreate={props.canCreate}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default Column;
