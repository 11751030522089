import { validateIngredient } from "../../utils/recipeHelpers";

export default state => {
  let hasErrors = false;
  const errors = {};

  if (!state.title || state.title.length < 6) {
    hasErrors = true;
    errors.title = "Title is required (minimum 6 characters)";
  }

  if (!state.type.length) {
    hasErrors = true;
    errors.type = "Choose at least one type";
  }

  errors.carbs = {};

  errors.fat = {};

  errors.protein = {};
  if(state.ingredients.filter(i=>validateIngredient(i)).length===0) {
    hasErrors = true;
    errors.empty="Please add at least one ingredient to the recipe."
  } else {
    errors.ingredients = new Array(state.ingredients.length).fill(null);
  state.ingredients.forEach((ingredient, index) => {

      errors.ingredients[index] = {};
      if(ingredient.heading) {
        if(!ingredient.name) {
          hasErrors = true;
        errors.ingredients[index].name =
          "Please, enter the heading title";
        }
        
      } else {
        if (!ingredient.name) {
          hasErrors = true;
          errors.ingredients[index].name =
            "Please, select product";
        }
  
        if (!ingredient.name || !ingredient.name.name ) {
          hasErrors = true;
          errors.ingredients[index].name =
            "Name is required";
        }
  
        if (!ingredient.quantity) {
          hasErrors = true;
  
          errors.ingredients[index].quantity = "Quantity is required";
          
        } else if(!ingredient.shoppingList||!ingredient.shoppingList.amount) {
          hasErrors = true;
  
          errors.ingredients[index].quantity = "Shopping Quantity is required";
        }
        if(!/^((\.[0-9]*)|[0-9]+((\.[0-9]*)|((\s?[0-9]+)?\/[0-9]*))?)$/.test(ingredient.quantity)) {
            hasErrors=true;
            errors.ingredients[index].quantity ="Invalid Quantity";
        } else if(ingredient.displayAmount&&!/^((\.[0-9]*)|[0-9]+((\.[0-9]*)|((\s?[0-9]+)?\/[0-9]*))?)$/.test(ingredient.quantity)) {
          hasErrors=true;
          errors.ingredients[index].displayAmount ="Invalid Quantity";
      } else if(ingredient.shoppingList&&!/^((\.[0-9]*)|[0-9]+((\.[0-9]*)|((\s?[0-9]+)?\/[0-9]*))?)$/.test(ingredient.shoppingList.amount)) {
          hasErrors=true;
          errors.ingredients[index].quantity ="Invalid Shopping Quantity";
      }
        if (!ingredient.measure) {
          hasErrors = true;
          errors.ingredients[index].measure = "Measurement unit is required";
        } else if(!ingredient.shoppingList||!ingredient.shoppingList.unit) {
          hasErrors = true;
          errors.ingredients[index].measure = "Shopping Measurement unit is required";
        }
  
        if (!ingredient.category) {
          hasErrors = true;
          errors.ingredients[index].category = "Category is required";
        }
      }

  });

  }

  if (hasErrors) {
    return errors;
  }

  return false;
};
