import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import Input from "../../../../forms/Input";
import Checkbox from "../../../../forms/Checkbox";
import { SUBSCRIPTION_STATUSES, SUBSCRIPTION_TYPES_NAMES } from "../../index";
import { MultySelect } from "../../../../components/MultySelect";

const UsersFilters = ({ filter, onSubmit }) => {
  const [filters, setFilters] = useState(filter);

  const onStateChange = (type, value) => {
    setFilters((prevState) => {
      const newFilters = {
        ...prevState,
        [type]: value,
      };
      onSubmit(newFilters);
      return newFilters;
    });
  };

  return (
    <>
      <Row style={{ marginBottom: "24px", marginTop: "24px" }}>
        <Col md={5}>
          <MultySelect
            label={"Subscription type"}
            placeholder={"Select types"}
            value={
              SUBSCRIPTION_TYPES_NAMES.filter((item) =>
                filters.subscriptionTypes.includes(item.key)
              )?.map((item) => ({ label: item.value, value: item.key })) || []
            }
            options={SUBSCRIPTION_TYPES_NAMES.map((item) => ({
              label: item.value,
              value: item.key,
            }))}
            onChange={(value) => onStateChange("subscriptionTypes", value)}
          />
        </Col>
        <Col md={5}>
          <MultySelect
            label={"Subscription status"}
            placeholder={"Select statuses"}
            value={
              SUBSCRIPTION_STATUSES.filter((item) =>
                filters.subscriptionStatuses.includes(item)
              )?.map((item) => ({ label: item, value: item })) || []
            }
            options={SUBSCRIPTION_STATUSES.map((item) => ({
              label: item,
              value: item,
            }))}
            onChange={(value) => onStateChange("subscriptionStatuses", value)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <span className="label">Joined</span>
          <div className="checkboxes-list">
            <Row justify="between">
              <Col md={6}>
                <Input
                  label="From"
                  placeholder="Type here"
                  type="date"
                  value={filters.registrationDateStart}
                  onChange={(e) =>
                    onStateChange("registrationDateStart", e.target.value)
                  }
                />
              </Col>
              <Col md={6}>
                <Input
                  label="To"
                  placeholder="Type here"
                  type="date"
                  value={filters.registrationDateEnd}
                  onChange={(e) =>
                    onStateChange("registrationDateEnd", e.target.value)
                  }
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={5}>
          <span className="label">Last activity</span>
          <div className="checkboxes-list">
            <Row justify="between">
              <Col md={6}>
                <Input
                  label="From"
                  placeholder="Type here"
                  type="date"
                  value={filters.lastActivityStart}
                  onChange={(e) =>
                    onStateChange("lastActivityStart", e.target.value)
                  }
                />
              </Col>
              <Col md={6}>
                <Input
                  label="To"
                  placeholder="Type here"
                  type="date"
                  value={filters.lastActivityEnd}
                  onChange={(e) =>
                    onStateChange("lastActivityEnd", e.target.value)
                  }
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={2}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "32px",
                marginBottom: "24px",
              }}
            >
              <Checkbox
                name="Deleted users"
                value={filters.deletedOnly}
                onChange={() =>
                  onStateChange("deletedOnly", !filters.deletedOnly)
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UsersFilters;
